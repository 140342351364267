export const CalculatorSchema = (props) => {
    return {
        title: 'Rechner',
        fieldsets: [
            {
                id: 'default',
                title: 'Default',
                fields: ['field_one_name', 'field_two_name', 'field_one_max', 'field_two_max', 'field_one_min', 'field_two_min'],
            },
        ],

        properties: {
            field_one_name: {
                title: 'Name erstes Feld',
                description: 'Name des ersten Feldes',
                type: 'string',
                required: true,
            },
            field_one_max: {
                title: 'Max. erstes Feld',
                description: 'Maximale Anzahl des ersten Feldes',
                type: 'integer',
                required: true,
            },
            field_one_min: {
                title: 'Min. erstes Feld',
                description: 'Mindest Anzahl des ersten Feldes',
                type: 'integer',
            },
            field_two_name: {
                title: 'Name zweites Feld',
                description: 'Name des zweiten Feldes',
                type: 'string',
                required: true,
            },
            field_two_max: {
                title: 'Max. zweites Feld',
                description: 'Maximale Anzahl des zweiten Feldes',
                type: 'integer',
                required: true,
            },
            field_two_min: {
                title: 'Min. zweites Feld',
                description: 'Mindest Anzahl des zweiten Feldes',
                type: 'integer',
            },
        },
        required: [],
    };
};

export default CalculatorSchema;