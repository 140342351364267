import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const CalculatorView = ({ data }) => {
    var rangeBulletOne;
    var rangeSliderOne;
    var rangeBulletTwo;
    var rangeSliderTwo;
    var priceField;

    function showSliderValueOne() {
        rangeBulletOne.innerHTML = rangeSliderOne.value;
        calcPrice();
    }

    function showSliderValueTwo() {
        rangeBulletTwo.innerHTML = rangeSliderTwo.value;
        calcPrice();
    }

    function calcPrice() {
        var days = rangeSliderTwo.value;
        var people = rangeSliderOne.value;
        var price = 0;
        if (days != 0 | people != 0) {
            price = (days * 45) + (people * 20) + 50;
        }
        priceField.innerHTML = price;
    }

    useEffect(() => {
        rangeSliderOne = document.getElementById("rs-range-line-one");
        rangeBulletOne = document.getElementById("rs-bullet-one");
        rangeSliderTwo = document.getElementById("rs-range-line-two");
        rangeBulletTwo = document.getElementById("rs-bullet-two");
        priceField = document.getElementById("priceField");
    })

    return (
        <>
            <div className="container">
                <div className="rangeContainer">
                    <p>{data.field_one_name}</p>
                    <div class="range-slider">
                        <input onChange={showSliderValueOne} id="rs-range-line-one" class="rs-range" type="range" defaultValue={data.field_one_min} min={data.field_one_min} max={data.field_one_max} />
                        <span id="rs-bullet-one" class="rs-label">{data.field_one_min}</span>
                    </div>

                    <div class="box-minmax">
                        <span>{data.field_one_min}</span><span>{data.field_one_max}</span>
                    </div>
                </div>
                <div className="rangeContainer">
                    <p>{data.field_two_name}</p>
                    <div class="range-slider">
                        <input onChange={showSliderValueTwo} id="rs-range-line-two" class="rs-range" type="range" defaultValue={data.field_two_min} min={data.field_two_min} max={data.field_two_max} />
                        <span id="rs-bullet-two" class="rs-label">{data.field_two_min}</span>
                    </div>

                    <div class="box-minmax">
                        <span>{data.field_two_min}</span><span>{data.field_two_max}</span>
                    </div>
                </div>
                <div className="rangeContainer">
                    <p>Preis</p>
                    <p className="price"><strong id="priceField">0</strong> CHF</p>
                </div>
            </div>
        </>
    );
};

CalculatorView.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
};

export default CalculatorView;