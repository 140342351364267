import $ from 'jquery';

$(function () {
  if (screen.width > 1070) {
    $(".header-wrapper").data("size", "big");
  }
});

$(window).on("load", function () {
  $(".dropdownmenu-item").each(function (elem) {
    var pos = $(this).position().left;
    $(this).next(".dropdown-menu-wrapper").css("left", pos);
  })

  if (screen.width <= 990) {
    $(".ui.pointing.secondary.stackable.menu").children().wrapAll('<div class="mobileNavWrapper"></div>')
  }
})

$(window).on("resize", function () {
  if (screen.width > 1070) {
    $(".public-ui .header-wrapper").data("size", "big");
  }

  $(".dropdownmenu-item").each(function (elem) {
    var pos = $(this).position().left;
    $(this).next(".dropdown-menu-wrapper").css("left", pos);
  })
});

$(window).on("scroll", (function () {
  if (screen.width > 1070) {
    var imgHeight = $(":root").css("--imgHeight");
    var headerTop = $(":root").css("--headerTop");

    if ($(document).scrollTop() > 0) {
      if ($(".public-ui .header-wrapper").data("size") == "big") {
        $(".public-ui .header-wrapper").data("size", "small");
        $(".public-ui .header-wrapper").stop().animate({
          height: "6em",
          top: "0",
          "padding-top": "0",
          "padding-bottom": "0",
        }, 600);
        $(".public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img").stop().animate({
          height: "5.5em",
        }, 600);
        $(".public-ui .bgImg").stop().animate({
          height: "0",
        }, 800);
        $(".public-ui #page-document").stop().animate({
          "padding-top": "13em",
        }, 600);
      }
    }
    else {
      if ($(".public-ui .header-wrapper").data("size") == "small") {
        $(".public-ui .header-wrapper").data("size", "big");
        $(".public-ui .bgImg").stop().animate({
          height: imgHeight,
        }, 600);
        $(".public-ui .header-wrapper").stop().animate({
          height: "12em",
          top: headerTop,
          "padding-top": "14px",
          "padding-bottom": "14px",
        }, 600);
        $(".public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img").stop().animate({
          height: "10em",
        }, 600);
        $(".public-ui #page-document").stop().animate({
          "padding-top": "0",
        }, 600);
      }
    }
  }
}));