import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent } from '@plone/volto/actions';
import PropTypes from 'prop-types';
import { Helmet } from '@plone/volto/helpers';
import { Image } from 'semantic-ui-react';
import 'remixicon/fonts/remixicon.css'
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

const getPeopleContent = (array = []) =>
    array.map((obj, item) => {
        obj[0] = item;
        return obj;
    }, {}
    );

const IsotopeView = ({ data }) => {

    const dispatch = useDispatch();
    const people = useSelector((state) =>
        getPeopleContent(state.search.subrequests.person?.items),
    );

    const content = useSelector((state) => state.workflow.transition);

    useEffect(() => {
        dispatch(
            searchContent(
                '/',
                {
                    portal_type: ['person'],
                    fullobjects: true,
                },
                'person',
            ),
        );
    }, [dispatch, content]);

    const handleClick = event => {
        const buttons = document.getElementsByClassName('is-checked');
        Array.prototype.forEach.call(buttons, function (button) {
            button.classList.remove('is-checked');
        })
        event.currentTarget.classList.toggle('is-checked');
    };

    // Init isotope
    const isotope = useRef();
    // store the filter keyword in a state  
    const [filterKey, setFilterKey] = useState('*')
    //set the state to initialize isotope
    const [isStateTrue, setIsStateTrue] = useState(false)

    const handleFilterKeyChange = key => (event) => { setFilterKey(key); handleClick(event); }

    const setHeight = () => {
        setIsStateTrue(true)
    }

    useEffect(() => {
        if (people.length > 1) {
            isotope.current = new Isotope('.gridIsotope', {
                itemSelector: '.grid-item',
                sortBy: 'random',
                layoutMode: 'fitRows',
            })

            // isotope.current.shuffle()
            // setTimeout(function(){isotope.current.layout()}, 1000)

            // cleanup
            return () => isotope.current.destroy()
        }
    }, [isStateTrue])

    useEffect(() => {
        if (people.length > 1) {
            filterKey === '*'
                ? isotope.current.arrange({ filter: `*` })
                : isotope.current.arrange({ filter: `.${filterKey}` })
        }
    }, [filterKey])

    return (
        <>
            <Helmet>
                <script defer src="https://unpkg.com/isotope-layout@3/dist/isotope.pkgd.min.js"></script>
            </Helmet>
            <div id="filters" className="button-group filter-button-group">
                <button className="filterBtn is-checked" onClick={handleFilterKeyChange("*")}>Alle</button>
                {data.default_tags != undefined &&
                    data.default_tags.map((filter) => (
                        <button className="filterBtn" onClick={handleFilterKeyChange(filter.tagname)}>{filter.tagname}</button>
                    ))}
            </div>

            <hr />

            <Grid className="gridIsotope" container spacing={{ md: 8, sm: 8 }} rowSpacing={{ xs: 8 }} onLoad={(setHeight)}>
                {people.map((person) => (
                    <Grid item className={"grid-item " + String(person.tags_field).split(",").map(tag => tag).join(" ")} key={person.UID}>
                        <Image className="gridImg" src={person.img_field.download} alt={person.img_field.filename} loading="lazy" />
                        <div className="gridContent">
                            <div className="gridContentNameDiv"><p className="gridContentName">{person.name_field}</p></div>
                            <div className="stackWrapper">
                                <Stack spacing={2}>
                                    <p>Eintritt:</p>
                                    <p>Beruf:</p>
                                    <p>Hobby:</p>
                                    <p>Stimme:</p>
                                    <p>Funktion:</p>
                                </Stack>
                                <Stack spacing={2}>
                                    <p>{person.entrance_field}</p>
                                    <p>{person.beruf}</p>
                                    <p>{person.hobby}</p>
                                    <p>{person.stimme}</p>
                                    <p>{person.funktion}</p>
                                </Stack>
                            </div>
                            <div className="gridContentTagContainer">
                                {person.tags_field.length != 0 &&
                                    String(person.tags_field).split(",").map((tag, i) => (
                                        <div className="gridContentTagDiv" ><button className="gridContentTag" key={person.UID + i}>{tag}</button></div>
                                    ))}
                                {person.tags_field.length == 0 &&
                                    <div className="gridContentTagDiv noContent"><button className="gridContentTag">Platzhalter</button></div>
                                }
                            </div>
                        </div>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

IsotopeView.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
};

export default IsotopeView;