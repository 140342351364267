import React from 'react';
import SidebarPortal from '@plone/volto/components/manage/Sidebar/SidebarPortal';
import CalculatorData from './CalculatorData';

const CalculatorEdit = React.memo((props) => {
    const { selected, onChangeBlock, block, data } = props;
    return (
        <>
            <p>{props.data.field_one_name} | max: {props.data.field_one_max} | min: {props.data.field_one_min}</p>
            <p>{props.data.field_two_name} | max: {props.data.field_two_max} | min: {props.data.field_two_min}</p>
            <SidebarPortal selected={selected}>
                <CalculatorData
                    {...props}
                    data={data}
                    block={block}
                    onChangeBlock={onChangeBlock}
                />
            </SidebarPortal>
        </>
    )
})

export default CalculatorEdit;