import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


const Footer = ({ intl }) => {
    const currentYear = new Date().getFullYear();

    return (
        <Segment role="contentinfo" padded id="footer">
            <div className="footerTop">

                <div className='logoWhiteDiv'>
                    <img src='../logo.png' alt='Logo White' className='logoWhite' />
                </div>

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item footerName">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Name"
                                    defaultMessage="Restaurant Bädli"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Adress"
                                    defaultMessage="Bädligässli 15"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Plz-Ort'
                                    defaultMessage="3550, Langnau i.E."
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-phone-line"></i>
                            <a className="item" href="tel:034 402 10 95">
                                <FormattedMessage
                                    id='Footer-Telefon'
                                    defaultMessage="034 402 10 95"
                                />
                            </a>
                        </div>
                    </List>
                </div>

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item footerName">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Oeffnungszeiten'
                                    defaultMessage="Öffnungszeiten"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-MI-DO'
                                    defaultMessage="Mittwoch - Donnerstag: 09:00 - 23:30"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-FR-SA'
                                    defaultMessage="Freitag - Samstag: 09:00 - 00:30"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-SO'
                                    defaultMessage="Sonntag: 09:00 - 22:00"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-MO-DI'
                                    defaultMessage="Montag - Dienstag: Geschlossen / Ruhetag"
                                />
                            </p>
                        </div>
                    </List>
                </div>
            </div>
            <hr />
            <div className='footerBottom'>
                <div>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <a className="item" href="./impressum">
                                <FormattedMessage
                                    id='Footer-Imprint'
                                    defaultMessage="Impressum"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <a className="item" href="./datenschutz">
                                <FormattedMessage
                                    id='Footer-Privacy'
                                    defaultMessage="Datenschutzerklärung"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-copyright-line"></i>
                            {currentYear}&nbsp;
                            <FormattedMessage
                                id='Footer-Copyright'
                                defaultMessage="Herrmann AG"
                            />
                        </div>
                    </List>
                </div>
            </div>
        </Segment>
    );
};

export default injectIntl(Footer);